import React from "react"

import styles from "./index.module.scss"

export default () => (
  <>
    <div className={`${styles.header}`}>
      <h2>Technical Official Introduction</h2>
      <p>
        The Introductory Technical Officials Course is a basic course designed
        to prepare a Technical Official (TO’s) for an on field position at iF3
        International Events.
      </p>

      <a href="/courses/toi/content">
        <i className="fas fa-shopping-cart" />
        Buy now
      </a>
    </div>

    <ul className={`${styles.container}`}>
      <li>
        <h3 className={`${styles.containerHeader}`}> About this Course</h3>

        <p>
          The Introductory Technical Officials Course is designed to prepare a
          Technical Official (TO’s) for an on field position at iF3
          International Events. This course introduces TO’s to the iF3 rulebook
          and discusses basic performance expectations when on the field. In
          order to successfully complete this course, the participant must
          complete an assessment consisting of an online examination. This
          preliminary course is required for participation as a TO in
          International events and to take additional courses TO courses
          available through the iF3.
        </p>
      </li>
    </ul>
  </>
)
